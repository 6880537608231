import React from 'react';
import './index.css';

import Home from './routes/Home';
import About from './routes/About';
import Portfolio from './routes/Portfolio';
import Music from './routes/Music'
import Contact from './routes/Contact';
import Thankyou from './routes/Thankyou';

import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/portfolio" element={<Portfolio />}/>
      <Route path="/music" element={<Music />} />
      <Route path="/contact" element={<Contact />}/>
      <Route path="/thank-you" element={<Thankyou />}/>
    </Routes>
    </>
  );
}

export default App;
