import React from 'react';
import './MusicPlayerStyles.css';
import { FaPlay, FaPause, FaBackward, FaForward } from 'react-icons/fa';


const AudioControls = ({
  isPlaying,
	onPlayPauseClick,
  onPrevClick,
  onNextClick,
}) => (<div className="audio-controls">
    <button
      id="prev"
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      <FaBackward size={20} style={{color: '#fff'}}/>
    </button>
    {isPlaying ? (
      <button
        id="pause"
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        <FaPause size={20} style={{color: '#fff'}}/>
      </button>
    ) : (
      <button
        id='play'
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        <FaPlay size={20} style={{color: '#fff'}}/>
      </button>
    )}
    <button
      id='next'
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      <FaForward size={20} style={{color: '#fff'}}/>
    </button>
  </div>
);

export default AudioControls;