import './NavStyles.css';

import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import logo from '../../assets/Logo.png';

import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = () => {

  const [open, close] = useState(false);
  const handleClick = () => close(!open);

  const [color, setColor] = useState(false);
  const changeColor = () =>{
    if(window.scrollY >= 100){
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll', changeColor)

  return (
    <div className={color ? 'header headerBG' : 'header'}>
      <Link to="/">
        <img src={logo} alt='logo' className='homeIcon'/>
      </Link>
      <ul className={open ? 'navMenu active' : 'navMenu'}>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li>
          <Link to="/music">Music</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div className='hamburger' onClick={handleClick}>
        { open ? (<FaTimes size={20} style={{color: '#fff' }}/>) : ( <FaBars size={20} style={{color: '#fff' }}/>) }
      </div>
    </div>
  )
}

export default Nav