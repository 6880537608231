import './AboutContentStyles.css';

import React from 'react'
import { Link } from 'react-router-dom';
import mta1 from '../../assets/certifications/MTA-Introduction_to_Programming_Using_HTML_and_CSS.png';
import mta2 from '../../assets/certifications/MTA-Introduction_to_Programming_Using_JavaScript.png';
import aboutImg from '../../assets/design/eatingmyselfloop.gif'

const AboutContent = () => {
  return (
    <div className='about'>
      <div className='left'>
        <h1>What I Do</h1>
        <p>I am a multidisciplinary artist and creator with a passion for design and music. A focused problem solver with the ability to develop projects from start to finish.</p>
        <p>I enjoy working in various mediums and much of my inspiration comes from travel, nature, and life experiences.
        </p>
        <p>
        It is my belief that each day presents a new opportunity to grow and learn. As a creator, I hope to be able to share these moments and the stories that they tell.
        </p><br></br>
        <Link to="/contact" className='btn'>Contact</Link>
        <div className='imgContainer'>
          <a href='https://www.credly.com/badges/308613d0-f4ec-4de8-a62b-59dc605cb1dc/public_url' target='_blank' rel='noreferrer'><img src={mta1} className='certImg' alt='certification' /></a>
          <a href='https://www.credly.com/badges/323bbcb7-737a-4e03-a5d6-564915ac0e41/public_url' target='_blank' rel='noreferrer'><img src={mta2} className='certImg' alt='certification' /></a>
        </div>
      </div>
      <div className='right'>
        <div className='aboutImage'>
          <img src={aboutImg} className='img' alt='About' />
        </div>
      </div>
    </div>
  )
}

export default AboutContent