import momentary from '../../assets/web/Momentary-Image.png';
import housePlant from '../../assets/web/houseplant.jpg'
import veggeLabs from '../../assets/web/veggeLabs.jpg';
import snake from '../../assets/web/snake.gif';
import listApp from '../../assets/web/list-app.png';
import americanSpirit from '../../assets/art/american_spirit.jpg';
import fear from '../../assets/art/fear.jpg';
import oporto from '../../assets/art/Oporto.jpg';
import canoa from '../../assets/art/Untitled.jpg';
import ice from '../../assets/art/Ice_Landscape.jpg';
import landscapeOfTheMind from '../../assets/art/Landscape_of_the_Mind.jpg';
import reflect from '../../assets/art/Reflect.jpg';
import blue from '../../assets/art/Blue.jpg';
import lavaLamp from '../../assets/art/Wire_Lava_Lamp.jpg';
import flight from '../../assets/design/bat.gif';
import bonsai from '../../assets/design/bonzai.jpg';
import cacti from '../../assets/design/cacti.jpg';
import chew from '../../assets/design/Chew.gif';
import chimpanzee from '../../assets/design/chimpanzee.jpg';
import crow from '../../assets/design/crow.jpg';
import ohDeer from '../../assets/design/Oh-Deer.gif';
import grow from '../../assets/design/flower.gif';
import spacedOut from '../../assets/design/SpacedOut.jpg';


const PortfolioCardData = [
  {
    imgsrc: momentary,
    title: 'Momentary',
    text: 'Open source modular plugin for VCV Rack. Built with C++ using the VCV Rack Open Source SDK',
    view: '',
    source: 'https://github.com/gh0sttttt/MomentaryPlugin',
  },
  {
    imgsrc: housePlant,
    title: 'HousePlant',
    text: 'An example of a simple website. Created using HTML + CSS',
    view: 'https://gh0sttttt.github.io/housePlant-website/',
    source: 'https://github.com/gh0sttttt/housePlant-website',
  },
  {
    imgsrc: veggeLabs,
    title: 'VeggeLabs',
    text: 'An example of a simple website design. Created using HTML, CSS, and BootStrap',
    view: 'https://gh0sttttt.github.io/VeggeLabs/',
    source: 'https://github.com/gh0sttttt/VeggeLabs',
  },
  {
    imgsrc: snake,
    title: 'Snake!',
    text: 'Snake game created with JavaScript',
    view: 'https://gh0sttttt.github.io/snakeGame/',
    source: 'https://github.com/gh0sttttt/snakeGame/',
  },
  {
    imgsrc: listApp,
    title: 'List App',
    text: 'A simple list app created with JavaScript',
    view: 'https://gh0sttttt.github.io/todo-list-app/',
    source: 'https://github.com/gh0sttttt/todo-list-app',
  },
  {
    imgsrc: americanSpirit,
    title: 'American Spirit',
    text: '3" x 3", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: fear,
    title: 'Fear',
    text: '24" x 24", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: oporto,
    title: 'Oporto',
    text: '20" x 20", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: canoa,
    title: 'Canoa Beach',
    text: '24" x 24", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: ice,
    title: 'Ice Landscape',
    text: '12" x 8", Acrylic on Canvas',
    view: '',
    source: '',
  },
   {
    imgsrc: landscapeOfTheMind,
    title: 'Landscape Of The Mind',
    text: '12" x 12", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: reflect,
    title: 'Reflect',
    text: '12" x 8", Acrylic on Canvas',
    view: '',
    source: '',
  },
   {
    imgsrc: blue,
    title: 'Blue',
    text: '24" x 24", Acrylic on Canvas',
    view: '',
    source: '',
  },
  {
    imgsrc: lavaLamp,
    title: 'Lava Lamp',
    text: 'Lava Lamp constructed from various wire',
    view: '',
    source: '',
  },
  {
    imgsrc: flight,
    title: 'Flight',
    text: '2d animation, pencil drawing animated in Photoshop',
    view: '',
    source: '',
  },
  {
    imgsrc: bonsai,
    title: 'GhostMatter Logo',
    text: 'Logo designed in Illustrator',
    view: '',
    source: '',
  },
  {
    imgsrc: cacti,
    title: 'GhostMatter Cacti Design',
    text: 'Digital Design, used to create t-shirts',
    view: '',
    source: '',
  },
  {
    imgsrc: chew,
    title: 'Chew Animation',
    text: 'Stop motion loop animation using a banana',
    view: '',
    source: '',
  },
  {
    imgsrc: chimpanzee,
    title: 'Chimpanzee',
    text: 'Poster design created with Photoshop',
    view: '',
    source: '',
  },
  {
    imgsrc: crow,
    title: 'Crow',
    text: 'Print Design, created with Photoshop',
    view: '',
    source: '',
  },
  {
    imgsrc: ohDeer,
    title: 'Oh Deer',
    text: '2d animation designed in Illustrator and animated in Photoshop',
    view: '',
    source: '',
  },
  {
    imgsrc: grow,
    title: 'Grow',
    text: '2d animatino, pencil drawing animated in Photoshop',
    view: '',
    source: '',
  },
  {
    imgsrc: spacedOut,
    title: 'Spaced Out',
    text: 'Digital Design, created in Photoshop',
    view: '',
    source: '',
  },

];

export default PortfolioCardData; 