import './FooterStyles.css';


import React from 'react'
import { FaGithub, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footerContainer'>
        <div className='footerContent'>
          <p>Copyright © 2022. All Rights Reserved</p>
         <div className='social'>
          <a href='https://github.com/gh0sttttt' target='_blank' rel='noreferrer'> <FaGithub size={30} style={{color: '#fff', marginRight: '1rem'}}/></a>
          <a href='https://linkedin.com/in/aaronabreu15/' target='_blank' rel='noreferrer'><FaLinkedinIn size={30} style={{color: '#fff', marginRight: '1rem'}}/></a>
          <a href='https://www.instagram.com/gh0sttttt_/' target='_blank' rel='noreferrer'><FaInstagram size={30} style={{color: '#fff', marginRight: '1rem'}}/></a>
         </div>
        </div>
      </div>
    </div>
  )
}

export default Footer