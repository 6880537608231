import React from 'react'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'
import HeroContentImg from '../components/Hero/HeroContentImg';
import Form from '../components/Form/Form';

const Contact = () => {
  return (
    <div>
      <Nav />
      <HeroContentImg heading='CONTACT ME' text="Let's Work Together!" />
      <Form />
      <Footer />
    </div>
  )
}

export default Contact