import './FormStyles.css';

import React from 'react'

const Form = () => {
  return (
    <div>
      <form className='contactForm' name="contact" method="POST" action="/thank-you" data-netlify="true" onSubmit="submit">
        <input type="hidden" name="form-name" value="contact" />

          <label>Name</label>
          <input name="name" required='true' type='text'></input>
          <label>Email</label>
          <input name="email" required ='true' type='email'></input>
          <label>Subject</label>
          <input name="subject" required='true' type='text'></input>
          <label>Message</label>
          <textarea name='message' required='true' rows="6" placeholder='Type your message here'></textarea>
          <button type="submit" className='btn'>Submit</button>
      </form>
    </div>
  )
}

export default Form