import React from 'react';
import Nav from '../components/Nav/Nav';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';
import HomeProjectPreview from '../components/Home/HomeProjectPreview';


const Home = () => {
  return (
    <div>
      <Nav />
      <Hero />
      <HomeProjectPreview />
      <Footer />
    </div>
  )
}

export default Home