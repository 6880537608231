import React from 'react'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'
import ThankyouImg from '../components/Thankyou/ThankyouImg';


const Contact = () => {
  return (
    <div>
      <Nav />
      <ThankyouImg heading='THANK YOU' text="Your Message Was Successfully Submitted!" />
      <Footer />
    </div>
  )
}

export default Contact