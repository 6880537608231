import './ProjectCardStyles.css';

import React from 'react'
import CardContent from './CardContent';
import PortfolioCardData from './PortfolioData';

const ProjectCard = () => {
  return (
    <div className='portfolioContainer'>
      <h1 className='portfolioHeading'>Portfolio</h1>
      <div className='projectContainer'>
      {PortfolioCardData.map((value, index) => {
        return (
          <CardContent 
          key={index}
          imgsrc={value.imgsrc}
          title={value.title}
          text={value.text}
          view={value.view}
          source={value.source}
          />
        )
      })} 
      </div>
    </div>
  )
}

export default ProjectCard