import './HeroStyles.css';
import React from 'react';

import HeroBG from '../../assets/waterBG.jpg';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='hero'>
      <div className='mask'>
        <img className='heroImg' src={HeroBG} alt='Hero Background'/>
      </div>
      <div className='content'>
        <p>
          Hi, I'm Aaron!
        </p>
        <h1>Developer & Designer</h1>
        <div>
          <Link to='/portfolio' className='btn'>Portfolio</Link>
          <Link to='/contact' className='btn btnLight'>Contact</Link>
        </div>
      </div>
    </div>
  )
}

export default Hero