import React from 'react'
import Footer from '../components/Footer/Footer';
import HeroContentImg from '../components/Hero/HeroContentImg';
import Nav from '../components/Nav/Nav';
import MusicPlayerWidget from '../components/MusicPlayer/MusicPlayerWidget';

const Music = () => {
  return (
    <div>
      <Nav />
      <HeroContentImg heading='MUSIC' text='Explore Sound Design and Music'/>
      <MusicPlayerWidget />
      <Footer />
    </div>
  )
}

export default Music