import React, { useState, useEffect, useRef } from "react";
import './MusicPlayerStyles.css';
import AudioControls from './AudioControls';
import musicIcon from '../../assets/music-icon.jpg';
import { audioSource } from './musicData.js';

export default function MusicPlayer() {

  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const { title, artist, audioSrc } = audioSource[trackIndex]

  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  const { duration } = audioRef.current;

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
    setTrackIndex(audioSource.length - 1);
  } else {
    setTrackIndex(trackIndex - 1);
  }
  }

  const toNextTrack = () => {
    if (trackIndex < audioSource.length - 1) {
    setTrackIndex(trackIndex + 1);
  } else {
    setTrackIndex(0);
  }
  }

  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	const startTimer = (() => {
	  // Clear any timers already running
	  clearInterval(intervalRef.current);

	  intervalRef.current = setInterval(() => {
	    if (audioRef.current.ended) {
	      toNextTrack();
	    } else {
	      setTrackProgress(audioRef.current.currentTime);
	    }
	  }, [1000]);
	})

  useEffect(() => {
  if (isPlaying) {
    audioRef.current.play();
    startTimer();
  } else {
    audioRef.current.pause();
  }
}, [isPlaying, startTimer]);

useEffect(() => {
  // Pause and clean up on unmount
  return () => {
    audioRef.current.pause();
    clearInterval(intervalRef.current);
  }
}, []);

// Handle setup when changing tracks
useEffect(() => {
  audioRef.current.pause();

  audioRef.current = new Audio(audioSrc);
	setTrackProgress(audioRef.current.currentTime);

  if (isReady.current) {
    audioRef.current.play();
    setIsPlaying(true);
    startTimer();
  } else {
    // Set the isReady ref as true for the next pass
    isReady.current = true;
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [trackIndex]);

const onScrub = (value) => {
	// Clear any timers already running
  clearInterval(intervalRef.current);
  audioRef.current.currentTime = value;
  setTrackProgress(audioRef.current.currentTime);
}

const onScrubEnd = () => {
  // If not already playing, start
  if (!isPlaying) {
    setIsPlaying(true);
  }
  startTimer();
}

const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
`;

  return(
    <div className='container'>
      <div className="audio-player">
        <div className="track-info">
          <img
            className="artwork"
            src={musicIcon}
            alt={`track artwork for ${title} by ${artist}`}
          />
          <h2 className="title">{title}</h2>
          <h3 className="artist">{artist}</h3>
          <AudioControls
            isPlaying={isPlaying}
            onPrevClick={toPrevTrack}
            onNextClick={toNextTrack}
            onPlayPauseClick={setIsPlaying}
          />
          <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
        </div>
      </div>
    </div>
  );
}