import './HomeProjectPreviewStyles.css';

import React from 'react'
import { MdComputer, MdDesignServices } from 'react-icons/md';
import { FaMusic, FaPaintBrush } from 'react-icons/fa';

const HomeProjectPreview = () => {
  return (
    <div className='specialtyContainer'>
      <div className='specialSkills' id='developer'>
        <MdComputer size={40} className='icon'/>
        <h4 className='specialTitle'>
          Developer
        </h4>
        <p className='specialDetails'>
          I work with various languages and technologies to produce stunning websites and applications. Languages and frameworks I use include HTML, CSS, Javascript, React, JUCE and C++.
        </p>
      </div>
      <div className='specialSkills' id='design'>
        <MdDesignServices size={40} className='icon'/>
        <h4 className='specialTitle'>
          Designer
        </h4>
        <p className='specialDetails'>
          I have a high attention to detail and natural eye for creative visual designs with experience across a wide array of industries. Including graphic design, lighting, floral, web, animation, UX.
        </p>
      </div>
      <div className='specialSkills' id='art'>
        <FaPaintBrush size={40} className='icon'/>
        <h4 className='specialTitle'>
          Artist
        </h4>
        <p className='specialDetails'>
          I enjoy working in a variety of mediums including Painting, Sculpture, Animation, 2D mediums, and mixed medias.
        </p>
      </div>
      <div className='specialSkills'>
        <FaMusic size={40} className='icon'/>
        <h4 className='specialTitle'>
          Musician
        </h4>
        <p className='specialDetails'>
          I also produce electronic and ambient music. I use an array of electronic instruments including analog, digital, and modular synthesizers to create vivid and melodic rhythms and textures.
        </p>
      </div>
    </div>
  )
}

export default HomeProjectPreview