import React from 'react'
import Footer from '../components/Footer/Footer';
import HeroContentImg from '../components/Hero/HeroContentImg';
import Nav from '../components/Nav/Nav';
import ProjectCard from '../components/Portfolio/ProjectCard';
import Scroll from '../components/Scroll';


const Portfolio 
 = () => {
  return (
    <div>
      <Nav />
      <HeroContentImg heading='THE CREATIVE PROCESS' text='Explore Projects and Designs'/>
      <Scroll>
        <ProjectCard />
      </Scroll>
      <Footer />
    </div>
  )
}

export default Portfolio 
