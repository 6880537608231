import gh0st from '../../assets/music/Ghost.mp3';
import winter from '../../assets/music/Winter.mp3';
import halloween from '../../assets/music/Hallows Eve.mp3';
import groovy from '../../assets/music/Groovy.mp3';
import whir from '../../assets/music/Whir.mp3';
import vertigo from '../../assets/music/Vertigo.mp3';
import smiles from '../../assets/music/:-).mp3';
import anywhere from '../../assets/music/Anywhere.mp3';
import begin from '../../assets/music/Begin.mp3';
import dream from '../../assets/music/Dream.mp3';
import midnight from '../../assets/music/Midnight.mp3';
import opinions from '../../assets/music/Opinions.mp3';

export const audioSource = [
  {
  title: 'Ghost',
  artist: '3A',
  audioSrc: gh0st,
  color: String,
  },
   {
  title: 'Winter',
  artist: '3A',
  audioSrc: winter,
  color: String,
  },
    {
  title: 'Halloween',
  artist: '3A',
  audioSrc: halloween,
  color: String,
  },
   {
  title: 'Groovy',
  artist: '3A',
  audioSrc: groovy,
  color: String,
  },
  {
  title: 'Whir',
  artist: '3A',
  audioSrc: whir,
  color: String,
  },
   {
  title: 'Vertigo',
  artist: '3A',
  audioSrc: vertigo,
  color: String,
  },
    {
  title: 'Smiles',
  artist: '3A',
  audioSrc: smiles,
  color: String,
  },
   {
  title: 'Anywhere',
  artist: '3A',
  audioSrc: anywhere,
  color: String,
  },
  {
  title: 'Begin',
  artist: '3A',
  audioSrc: begin,
  color: String,
  },
   {
  title: 'Dream',
  artist: '3A',
  audioSrc: dream,
  color: String,
  },
  {
  title: 'Midnight',
  artist: '3A',
  audioSrc: midnight,
  color: String,
  },
   {
  title: 'Opinions',
  artist: '3A',
  audioSrc: opinions,
  color: String,
  },
];