import './ProjectCardStyles.css';
import ModalImage from 'react-modal-image';



const CardContent = (props) => {

  const showView = props.view;
  const showSource = props.source

  return (
     <div className='projectCard'>
          <ModalImage smallSrcSet={props.imgsrce} small={props.imgsrc} large={props.imgsrc} hideDownload hideZoom/>
          <h2 className='projectTitle'>{props.title}</h2>
          <div className='projectDetails'>
            <p>{props.text}</p>
            <div className='projectBtns'>
              <a href={props.view} target='_blank' rel='noreferrer' className={showView ? 'btn' : 'btnHidden'}>View</a>
              <a href={props.source} target='_blank' rel='noreferrer' className={showSource ? 'btn' : 'btnHidden'} >Source</a> 
            </div>
          </div>
      </div>
  )
}

export default CardContent