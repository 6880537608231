import React from 'react'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'
import HeroContentImg from '../components/Hero/HeroContentImg';
import AboutContent from '../components/About/AboutContent';

const About = () => {
  return (
    <div>
      <Nav />
      <HeroContentImg heading='ABOUT' text="" />
      <AboutContent />
      <Footer />
      </div>
  )
}

export default About